import { createRouter, createWebHistory } from "vue-router"
import { checkMiddleware } from '@/middlewares/pipeline'
import store from '@/stores'

import DashboardLayout from "@/layouts/DashboardLayout"
import dashboardRoutes from '@/routes/Dashboard'
import flkRoutes from '@/routes/Flk'
import jobVacancyRoutes from '@/routes/JobVacancy'
import catogoryRoutes from '@/routes/Category'

import authMiddleware from '@/middlewares/auth'
import superadminMiddleware from '@/middlewares/superadmin'

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      ...dashboardRoutes,
      ...flkRoutes,
      ...jobVacancyRoutes,
      ...catogoryRoutes
    ],
    meta: {
      middleware: [
        authMiddleware, superadminMiddleware
      ]
    }
  },
  {
    path: "/authorize",
    component: () => import(/* webpackChunkName: "auth-pages" */ '@/views/Auth/Authorize.vue')
  },
  {
    path: '/flk/print/:id',
    name: 'Print Flk',
    component: () => import(/* webpackChunkName: "flk-pages" */ '@/views/Flk/PrintFlk.vue'),
    meta: {
      title: 'Print Flk - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Print Flk - ' + process.env.VUE_APP_TITLE,
      description: 'Print flk paninti karir',
      keywords: 'print flk,paninti karir'
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (!checkMiddleware({ to, from, next, store })) next()
})

router.afterEach(() => {
  // 
})

export default router;
