<template>
  <div class="bl-container">
    <p class="bl-label">{{ label }}</p>
    <p class="bl-value">{{ value }}</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="scss" scoped>
.bl-container {
  margin-bottom: 3px;

  .bl-label {
    margin-bottom: 3px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #a0a3bd;
  }
  .bl-value {
    margin-bottom: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #4e4b66;
  }
}
</style>