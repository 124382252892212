import { createStore } from 'vuex'

import configModule from '@/stores/config'
import authModule from '@/stores/Auth/auth.js'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    'config': configModule,
    'auth': authModule
  }
})
