<template>
  <div class="social-media-container">
    <div
      class="social-media-icon-container"
      :style="'width: ' + size +'px;' + 'height: ' + size +'px;'"
    >
      <img
        :src="iconUrl"
        alt="Linked In"
        class="social-media-icon"
      />
    </div>
    <div
      v-if="label !== ''"
      class="social-media-label"
    >{{ label }}</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'social-icon-pk-1',

  props: {
    iconUrl: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '32',
    },
    label: {
      type: String,
      default: ''
    }
  },
})
</script>

<style lang="scss" scoped>
.social-media-container {
  display: flex;
  align-items: center;

  .social-media-icon-container {
    background-color: #fff;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;

    .social-media-icon {
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }

  .social-media-label {
    margin-left: 8px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
}

.icon-sm {
  width: 32px;
  height: 32px;
}

.icon-md {
  width: 40px;
  height: 40px;
}
</style>