const routes = [
  {
    path: '/flk',
    name: 'Kelola Flk',
    component: () => import(/* webpackChunkName: "flk-pages" */ '@/views/Flk/ManageFlk.vue'),
    meta: {
      title: 'Kelola Flk - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Kelola Flk - ' + process.env.VUE_APP_TITLE,
      description: 'Kelola flk paninti karir',
      keywords: 'manage flk,paninti karir',
    },
  },
  {
    path: '/flk/detail/:id',
    name: 'Detail Flk',
    component: () => import(/* webpackChunkName: "flk-pages" */ '@/views/Flk/DetailFlk.vue'),
    meta: {
      title: 'Detail Flk - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Detail Flk - ' + process.env.VUE_APP_TITLE,
      description: 'Detail flk paninti karir',
      keywords: 'detail flk,paninti karir'
    },
  },
  {
    path: '/flk/:id',
    name: 'Ubah Flk',
    component: () => import(/* webpackChunkName: "flk-pages" */ '@/views/Flk/EditFlk.vue'),
    meta: {
      title: 'Ubah Flk - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Ubah Flk - ' + process.env.VUE_APP_TITLE,
      description: 'Ubah flk paninti karir',
      keywords: 'ubah flk,paninti karir'
    },
  },
]

export default routes
