import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import store from './stores'
import { createMetaManager, defaultConfig } from 'vue-meta'

import ElementPlus from 'element-plus'
import ArgonDashboard from './plugins/argon-dashboard'
import 'element-plus/lib/theme-chalk/index.css'
import 'nprogress/nprogress.css'
import '@/assets/scss/paninti-karir.scss'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(createMetaManager(false, {
  ...defaultConfig,
  meta: {
    tag: 'meta',
    nameless: true
  }
}))
app.use(ElementPlus)
app.use(ArgonDashboard)
app.mount('#app')
