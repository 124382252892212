import stores from "@/stores"
import axios from "axios"
import { StatusCodes } from "http-status-codes"

const apiClientGuest = axios.create({
  baseURL: String(process.env.VUE_APP_BASE_URL_API),
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'en'
  }
})

const apiClientAuth = axios.create({
  baseURL: String(process.env.VUE_APP_BASE_URL_API),
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
    Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
})

apiClientAuth.interceptors.request.use((config) => {
  return config
}, (error) => {
  return Promise.reject(error)
})

apiClientAuth.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === StatusCodes.UNAUTHORIZED || error.response.status === StatusCodes.FORBIDDEN) {
    stores.commit('user/resetUserLoggedIn', null, { root: true })
    window.location = process.env.VUE_APP_BASE_URL_LOGOUT
  }

  return Promise.reject(error)
})

export { apiClientAuth, apiClientGuest }