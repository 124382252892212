
const middlewarePipeline = (
  context, middleware, index
) => {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(
      context, middleware, index + 1
    )

    nextMiddleware({
      ...context,
      next: nextPipeline,
      nextVue: context.next
    })

  }

}

const checkMiddleware = (context) => {
  if (context.to.meta.middleware && Array.isArray(context.to.meta.middleware) && context.to.meta.middleware.length) {
    const middleware = context.to.meta.middleware

    middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
      nextVue: context.next
    })

    return true
  }

  return false
}

export { middlewarePipeline, checkMiddleware }
