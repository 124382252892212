import { apiClientAuth } from '@/utils/apiClient'

export default {
  getUserLoggedIn(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/user/info',
      ...payload
    })
  }
}