import { reactive } from 'vue'
import nProgress from 'nprogress'
import { StatusCodes } from 'http-status-codes';

export default function useAxios(fn, options = { }) {
  const state = reactive({
    statusCode: 0,
    response: undefined,
    loading: false,
    error: undefined,
    errorMessage: '',
    body: undefined,
    data: undefined
  })

  const createPromise = async (...config) => {
    state.response = undefined
    state.loading = true
    state.error = undefined

    if (options?.withIndicator) {
      nProgress.start()
    }

    try {
      const response = await fn(...config)
      state.response = response
      state.body = response.data
      state.data = response.data.data
      state.statusCode = response.status

    } catch (err) {
      state.error = err
      state.errorMessage = err.response
        ? err.response.data.message
        : err.message

      if (!state.errorMessage || state.errorMessage === '') {
        state.errorMessage = 'Terjadi kesalahan di server'
      }
      state.statusCode = err.response ? err.response.status : StatusCodes.INTERNAL_SERVER_ERROR
    } finally {
      state.loading = false

      if (options?.withIndicator) {
        nProgress.done()
      }
    }
  }

  return {
    state,
    createPromise
  }
}