const state = () => ({
  // 
})

const mutations = {
  // 
}

const actions = {
  // 
}

const getters = {
  baseUrlAPi: () => {
    return process.env.VUE_APP_BASE_URL_API
  },
  appUrl: () => {
    return process.env.VUE_APP_BASE_URL
  },
  devName: () => {
    return process.env.VUE_APP_DEV_NAME
  },
  devUrl: () => {
    return process.env.VUE_APP_DEV_URL
  },
  devFacebookUrl: () => {
    return process.env.VUE_APP_DEV_FACEBOOK_URL
  },
  devIntagramUrl: () => {
    return process.env.VUE_APP_DEV_INSTAGRAM_URL
  },
  devLinkedInUrl: () => {
    return process.env.VUE_APP_DEV_LINKEDIN_URL
  },
  devYoutubeUrl: () => {
    return process.env.VUE_APP_DEV_YOUTUBE_URL
  },
  devAddress: () => {
    return process.env.VUE_APP_DEV_ADDRESS
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}