const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard-pages" */ '@/views/Dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Dashboard - ' + process.env.VUE_APP_TITLE,
      description: 'dashboard paninti karir',
      keywords: 'dashboard,paninti karir',
    },
  },
]

export default routes
