<template>
  <span class="jv-tag">{{ name }}</span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "tag-pk-1",

  props: {
    name: {
      type: String,
      default: ""
    }
  }
})
</script>

<style lang="scss" scoped>
.jv-tag {
  background: #dceafa;
  border: none;
  border-radius: 18px;
  padding: 8px 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1659ab;
  cursor: default;
}
</style>
