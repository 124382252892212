<template>
  <div
    class="wrapper"
    :class="{ 'nav-open': $sidebar.showSidebar }"
  >
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template v-slot:links>
        <sidebar-item :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }" />
        <sidebar-item :link="{
            name: 'FLK',
            icon: 'ni ni-collection text-danger',
            path: '/flk',
          }" />
        <sidebar-item :link="{
            name: 'Lowongan Kerja',
            icon: 'ni ni-briefcase-24 text-success',
            path: '/job-vacancy',
          }" />
        <sidebar-item :link="{
            name: 'Kategori',
            icon: 'ni ni-bullet-list-67 text-primary',
            path: '/category',
          }" />
      </template>
    </side-bar>
    <div
      class="main-content"
      :data="sidebarBackground"
    >
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<style lang="scss">
.page-content {
  min-height: 94vh;
}
</style>
