import useAxios from '@/composables/useAxios'
import userService from '@/services/User'

export const superadminRoleName = 'superadmin'

export const getAndsaveUserLoggedInCreadentials = async (store) => {
  const getUserLoggedInPromised = useAxios((payload = {}) => {
    return userService.general.getUserLoggedIn(payload)
  })

  await getUserLoggedInPromised.createPromise()

  if (getUserLoggedInPromised.state.response) {
    store.commit('auth/setUserLoggedIn', getUserLoggedInPromised.state.data, { root: true })
  }
}