import { getAndsaveUserLoggedInCreadentials } from '@/composables/useAuthentication'

export default async function auth({ next, store }) {
  if (!store.getters['auth/accessToken']) {
    window.location = process.env.VUE_APP_BASE_URL_LOGIN
  } else {
    if (!store.getters['auth/userLoggedIn']) {
      await getAndsaveUserLoggedInCreadentials(store)
    }

    next()
  }
}