import { superadminRoleName } from "@/composables/useAuthentication";
import jwt_decode from "jwt-decode";

export default async function superadmin({ next, store }) {
  if (!store.getters['auth/accessToken']) {
    window.location = process.env.VUE_APP_BASE_URL_LOGIN
  } else {
    try {
      const jwtData = jwt_decode(store.getters['auth/accessToken'])

      const firstScope = jwtData.scopes[0] ?? ''

      if (firstScope !== superadminRoleName) {
        throw new Error('This user is not allowed to login')
      }
    } catch (error) {
      store.commit('auth/resetAccessToken')
      store.commit('auth/resetUserLoggedIn')
      window.location = process.env.VUE_APP_BASE_URL_LOGOUT
    }

    next()
  }
}