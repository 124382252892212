const routes = [
  {
    path: '/job-vacancy',
    name: 'Kelola Lowongan Kerja',
    component: () => import(/* webpackChunkName: "job-vacancy-pages" */ '@/views/JobVacancy/ManageJobVacancy.vue'),
    meta: {
      title: 'Kelola Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Kelola Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      description: 'Kelola job paninti karir',
      keywords: 'job,vacancy,manage,job vacancy,paninti karir'
    },
  },
  {
    path: '/job-vacancy/detail/:id',
    name: 'Detail Lowongan Kerja',
    component: () => import(/* webpackChunkName: "job-vacancy-pages" */ '@/views/JobVacancy/DetailJobVacancy.vue'),
    meta: {
      title: 'Detail Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Detail Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      description: 'Detail job paninti karir',
      keywords: 'job,vacancy,detail,job vacancy,paninti karir'
    },
  },
  {
    path: '/job-vacancy/add',
    name: 'Tambah Lowongan Kerja',
    component: () => import(/* webpackChunkName: "job-vacancy-pages" */ '@/views/JobVacancy/AddJobVacancy.vue'),
    meta: {
      title: 'Tambah Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Tambah Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      description: 'Tambah job paninti karir',
      keywords: 'job,vacancy,add,job vacancy,paninti karir'
    },
  },
  {
    path: '/job-vacancy/:id',
    name: 'Ubah Lowongan Kerja',
    component: () => import(/* webpackChunkName: "job-vacancy-pages" */ '@/views/JobVacancy/EditJobVacancy.vue'),
    meta: {
      title: 'Ubah Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Ubah Lowongan Kerja - ' + process.env.VUE_APP_TITLE,
      description: 'Ubah job paninti karir',
      keywords: 'ubah job,vacancy,ubah,job vacancy,paninti karir'
    },
  },
]

export default routes
