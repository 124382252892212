<template>
  <footer class="footer px-4 py-3">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-12">
        <div class="copyright text-center text-lg-left text-muted">
          © 2016 - {{ yearNow }}
          <a
            :href="devUrl"
            class="font-weight-bold ml-1"
            target="_blank"
          >{{ devName }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore()

    const yearNow = computed(() => new Date().getFullYear())
    const devName = computed(() => store.getters['config/devName'])
    const devUrl = computed(() => store.getters['config/devUrl'])

    return {
      yearNow,
      devName,
      devUrl
    }
  }
})
</script>
