const routes = [
  {
    path: '/category',
    name: 'Kelola Kategori',
    component: () => import(/* webpackChunkName: "category-pages" */ '@/views/Category/ManageCategory.vue'),
    meta: {
      title: 'Kelola Kategori - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Kelola Kategori - ' + process.env.VUE_APP_TITLE,
      description: 'Kelola category paninti karir',
      keywords: 'manage category,paninti karir'
    },
  },
  {
    path: '/category/add',
    name: 'Tambah Kategori',
    component: () => import(/* webpackChunkName: "category-pages" */ '@/views/Category/AddCategory.vue'),
    meta: {
      title: 'Tambah Kategori - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Tambah Kategori - ' + process.env.VUE_APP_TITLE,
      description: 'Tambah category paninti karir',
      keywords: 'add category,paninti karir'
    },
  },
  {
    path: '/category/:id',
    name: 'Ubah Kategori',
    component: () => import(/* webpackChunkName: "category-pages" */ '@/views/Category/EditCategory.vue'),
    meta: {
      title: 'Ubah Kategori - ' + process.env.VUE_APP_TITLE,
      mtitle: 'Ubah Kategori - ' + process.env.VUE_APP_TITLE,
      description: 'Ubah category paninti karir',
      keywords: 'ubah category,paninti karir'
    },
  },
]

export default routes
