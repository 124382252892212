const state = () => ({
  // 
})

const mutations = {
  setAccessToken(_state, payload) {
    localStorage.setItem('access-token', payload.token)
  },
  setUserLoggedIn(_state, payload) {
    localStorage.setItem('user-loggedin', JSON.stringify(payload.user))
  },
  resetAccessToken() {
    localStorage.removeItem('access-token')
  },
  resetUserLoggedIn() {
    localStorage.removeItem('user-loggedin')
  },
}

const actions = {
  // 
}

const getters = {
  isLoggedIn: () => {
    return localStorage.getItem('user-loggedin') && localStorage.getItem('access-token') ? true : false
  },
  userLoggedIn: () => {
    const defaultUserModel = {
      id: '',
      name: '',
      username: '',
      email: '',
      roles: [
        ''
      ]
    }

    let user = JSON.parse(localStorage.getItem('user-loggedin'))

    if (typeof user !== 'object' && user !== null && user !== undefined) {
      user = defaultUserModel
    }

    return user
  },
  accessToken: () => {
    return localStorage.getItem('access-token')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}